import { light } from '@stage-ui/core/themes'

const theme = light.replace({
  main: {
    radius: {
      xl: '0.75rem',
      l: '0.5rem',
      m: '0.375rem',
      s: '0.25rem',
      xs: '0.125rem',
    },
    color: {
      background: [255, 255, 255],
      primary: [208, 236, 96],
    },
  },
  overrides: {
    Button: () => ({
      container: {
        height: '4rem',
        fontWeight: 600,
        borderRadius: '1.25rem',
        padding: '1.125rem 2rem',
      },
    }),
  },
  assets: () => ({
    focus: {
      outline: 'none',
      boxShadow: '',
    },
    typography: {
      header: {
        xl: {
          fontSize: '3.25rem',
          lineHeight: '150%',
          margin: 0,
          fontWeight: 800,
        },
        l: {
          fontSize: '2.5rem',
          lineHeight: '150%',
          margin: 0,
        },
        m: {
          fontSize: '1.5rem',
          lineHeight: '2rem',
          margin: 0,
        },
        s: {
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
          margin: 0,
        },
        xs: {
          fontSize: '1.125rem',
          lineHeight: '1.5rem',
          margin: 0,
        },
      },
      paragraph: {
        xl: {
          fontSize: '1.5rem',
          lineHeight: '180%',
          weight: 500,
        },
        l: {
          fontSize: '1.25rem',
          lineHeight: '150%',
          weight: 500,
        },
      },
      text: {
        xl: {
          fontSize: '1.5rem',
          lineHeight: '180%',
          weight: 500,
        },
        l: {
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
        },
        m: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
        s: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
        },
        xs: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
        },
      },
    },
  }),
})

export default theme
